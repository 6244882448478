@font-face {
  font-family: "liberator"; 
  src: url("/fonts/Liberator.woff") format('woff'); 
}

.mydesigns-app {
  font-family: 'IBM Plex Sans', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'; 
}

.design-header-title {
  font-family: liberator;
  font-size: 60px;   
  margin-top: 0;
  line-height: 1em;
  font-style: 700; 
  padding: 5px; 
  white-space: nowrap;
  overflow: hidden;
}

.no-designs{
  font-family: liberator;
  font-size: 60px;   
}

.my-design-search-box {
  max-width: 300px; 
  padding: 5px; 
}

.design-table {
  // border: solid #e1e1e1 2px; 
  // border-radius: 5px; 
  margin: 10px 15px; 
  padding: 5px; 
}

.design-table-header {
  margin: 10px 15px; 
  // padding: 5px; 
  border-bottom: solid #e1e1e1 2px; 
} 

.design-table-item {
  margin: auto;
  padding: 3px; 
}

.design-item-preview {
  img {
    max-height: 120px;
  }
}

.design-item-edit {    
  background-color: red;
  font-weight: 700;
  color: white;
  padding: 15px;
  border-radius: 20px;
  width: 100%;
}
.design-item-share {    
  background-color: red;
  font-weight: 700;
  color: white;
  padding: 15px;
  border-radius: 20px;
  width: 100%;
}
.design-item-remove {
  margin: 10px; 
  svg:hover {
    color: red; 
  }
}

.design-table-pagination-lt{
  cursor: pointer
}

.pagination-val{
  font-weight: 700; 
  padding: 5px; 
}

.design-table-pagination-gt{
  cursor: pointer
}

.img-lgt-gray {
  background: lightgray;
  border: 2px solid darkgray;
}

.card-body-border {
  border-bottom: 2px;
  border-right: 2px;
  border-left: 2px;
  border-style: solid;
  border-color: darkgray;
}

.design--table_card {
  .card-body {
    .card-title {
        .design--table_id {
          font-family: "Forza-Bold";
        }
        .design--table_badge {
          font-size: 10px;
          margin: auto;
          color: white;
          border-radius: unset;
        }
      }
      .design--item_name {
        font-family: "Forza-Bold";
      }
      .design--table_dates {
        font-family: "Slate Std Regular";
        color: #707070
      }
  }
}