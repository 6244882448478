
.roster-details {
    overflow: hidden;
    overflow-y: auto;
    height: 300px;
  }
  
  .preview-images {
    // height: 135px;
    // width: 190px;
    // border-style: groove;
  }
  
  .custom-input-field {
    width: 122px;
    padding-left: 5px;
    margin-top: 5px;
    margin-left: 6px;
    height: 35px;
    border: 1px solid #d2d2d2 !important;
    background-color: #F5F5F5;
    justify-content: center;
  }
  
  .size-key{
    margin: 1px;
    font-weight: bolder;
    margin-left: 8px;
  }

  .price-key{
    margin: 1px;
    font-weight: bold;
    //font-family: "Slate-std-med";
    color: #b4b4b4;
    margin-right: 8px;
    font-size: 16px;
  }
  
  .table-bordered thead td, .table-bordered thead th{
    border-bottom-width:0px;
  }
  
  