.summary-tab-container {
  //width: 700px;
  height: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.decoration-details {
  overflow: hidden;
  overflow-y: auto;
  height: 300px;
}

.roster-details {
  overflow: hidden;
  overflow-y: auto;
  height: 300px;
}

.error-details {
  display: flex-center;
  align-items: center;
  text-align: center;
  margin-top: 30px;
}

.ViewSelector {
  display: flex;
  flex-direction: row;
}

.header-one-content {
  margin-bottom: 15px;
  height: 45px;
  border-bottom: groove;
}

.header-two-content {
  margin-bottom: 15px;
  height: 45px;
  border-bottom: groove;
}

.modalDisplay {
  display: block;
  position: fixed;
  background: white;
  width: 80%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.option-heading__title {
  font-family: $ub-font-forza-medium;
  font-size: 18px;
  font-weight:bold;
}

.tablerow {
  font-size: 14px;
  height: 40px;
  font-weight: bolder;
  padding: 10px;
  margin: 5px 0;
  background-color: lightgrey;
  color: black;
  display: flex;
  justify-content: center;
}

.text-location-summary-box {
  overflow: hidden;
}

.error-details {
  display: flex-center;
  align-items: center;
  text-align: center;
}

.ViewSelector {
  display: flex;
  flex-direction: row;
}
.preview-images {
  // height: 175px;
  // width: 190px;
  border-style: groove;
}

.download-pdf{
  text-align: right !important;
}

.ul-list {
  padding-left: 20px;
  text-align: left;
  list-style-position: outside;
  // list-style-position: inside;
  font-family: $ub-font-slate-std;
  font-size: 14px;
   
}

.design-summary{
  font-family: 'Forza-bold';
    font-size: 18px;
    margin-top: 50px;
    margin-bottom: 30px;
}

.summary-button{
  padding: 10px;
    font-family: 'Forza-bold';
    background-color: $ub-add-to-cart-button-color;
    color: white;
    outline: none;
    border: 0;
    border-radius: 4px;
    font-size: 16px;
    text-transform: uppercase;
}